@font-face {
    font-family: OpenSansReg;
    src: url(./font/OpenSans-Regular.ttf);
}
  
@font-face {
    font-family: OpenSansBold;
    src: url(./font/OpenSans-Bold.ttf);
}
  
@font-face {
    font-family: OpenSansLight;
    src: url(./font/OpenSans-Light.ttf);
}

body {
    font-family: OpenSansReg;
}

input:focus, textarea:focus {
    outline: none;
}
  
.input {
    border: 0px solid #fff;
    border-radius: 50em;
}

a {
    color: #E71E84;
}

.bold-text {
    font-family: OpenSansBold;
}

.auto-margin {
    margin: auto;
}

.full-screen-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main-menu {
    background-image: url(./assets/babcock_menus/main_menu/BG.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slow-fade {
    transition: opacity 0.7s;
}

.black-out {
    background-color: rgba(0, 0, 0, 0.7);
}

.black-bg {
    background-color: #000;
}

.white-bg {
    background-color: #FFF;
}

.green-bg {
    background-color: #cbd366;
}

.blue-glow {
    box-shadow: 0px 0px 100px 120px #0b86cc inset;
}

.half-fade {
    opacity: 0.5;
}

.babcock-menu-logo {
    width: 193px;
    height: 40px;
    margin: 45px 40px 0;
}

.plymouth-menu-logo {
    width: 118px;
    height: 90px;
    margin: 20px 40px 0;
}

.big-menu-logo {
    width: 583px;
    height: 513px;
    margin: -100px auto -100px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
}

.menu-logo-holder {
    width: 100%;
    height: 340px;
}

.menu-blurb {
    font-size: 16pt;
    max-width: 400px;
    margin: auto;
}

.main-go-blurb {
    font-size: 14pt;
    max-width: 480px;
    margin: 5px auto;
}

.main-go-blurb-small {
    font-size: 12pt;
    max-width: 500px;
    margin: 5px auto;
}

.main-go-blurb p {
    margin: 0px auto;
}

.settings-blurb {
    font-size: 18pt;
    max-width: 400px;
    margin: auto;
    color: #6C757D;
}

.selfie-blurb {
    font-size: 18pt;
    max-width: 440px;
    margin: auto;
    color: #6C757D;
}

.cta-blurb {
    font-size: 16pt;
    max-width: 540px;
    margin: 0 auto 20px;
    color: #6C757D;
}

.questions-blurb {
    font-size: 16pt;
    max-width: 440px;
    margin: 10px auto;
    color: #6C757D;
}

.results-blurb {
    font-size: 14pt;
    max-width: 440px;
    margin: 0 auto 10px;
    color: #6C757D;
}

.menu-play-button {
    width: 407px;
    height: 102px;
}

.menu-mute-button {
    width: 72px;
    height: 77px;
    margin: 20px 10px 0;
}

.settings-mute-button {
    width: 72px;
    height: 77px;
}

.menu-share-button {
    width: 177px;
    height: 77px;
    margin: 20px 10px 0;
}

.settings-share-button {
    width: 117px;
    height: 77px;
}

.copyright {
    font-size: 10pt;
    margin: 10px auto;
}

.cookies-bottom {
    background-color: #fff;
    color: #000;
}

.cookies-logo {
    width: 214px;
    height: 227px;
    margin: -180px auto 0px;
}

.blue-header {
    margin: 10px auto;
    font-size: 24pt;
    text-transform: uppercase;
    color: #0C499C;
}

.white-header {
    margin: 20px auto 10px;
    font-size: 24pt;
    text-transform: uppercase;
    color: #FFF;
}

.white-sub-heading {
    font-size: 16pt;
    margin: 0 auto 10px;
    color: #FFF;
}

.grey-info-text {
    margin: 0px auto 20px;
    font-size: 18pt;
    color: #6C757D;
}

.cookies-button {
    width: 213px;
    height: 77px;
    margin: 0px 10px 20px;
}

.gradient-bg {
    background-image: url(./assets/babcock_menus/select_character/BG.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.reward-bg {
    background-image: url(./assets/babcock_menus/reward_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.map-transition-bg {
    background-image: url(./assets/babcock_menus/map_transitions/bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.trans-bg-scroll-back-layer {
    position: absolute;
    top: 330px;
    left: 0px;
    width: 1280px;
    height: 182px;
    background-image: url(./assets/babcock_menus/map_transitions/buildingsBack.png);
    background-position: center;
    background-repeat: repeat-x;
    background-size: 50%;
    transition: left 2s;
    transition-timing-function: ease-in-out;
}

.trans-bg-scroll-front-layer {
    position: absolute;
    top: 380px;
    left: 0px;
    width: 1660px;
    height: 238px;
    background-image: url(./assets/babcock_menus/map_transitions/buildingsFront.png);
    background-position: top center;
    background-repeat: repeat-x;
    background-size: 65%;
    transition: left 2s;
    transition-timing-function: ease-in-out;
}

.bus-wheel {
    position: absolute;
    top: calc(58% + 30px);
    width: 37px;
    height: 37px;
    animation: wheel-spin 2s ease-in-out 0.3s;
}

.taxi-wheel {
    position: absolute;
    top: calc(58% + 6px);
    width: 37px;
    height: 37px;
    animation: wheel-spin 2s ease-in-out 0.3s;
}

@keyframes wheel-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.main-game-over-bg {
    background-image: url(./assets/babcock_menus/game_over/gamecomplete_bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.select-char-back-button {
    width: 107px;
    height: 77px;
    margin: 20px 40px 0;
}

.select-char-header {
    margin: 10px auto;
    font-size: 24pt;
    text-transform: uppercase;
}

.char-container {
    background-image: url(./assets/babcock_menus/select_character/bg_burst.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
}

.char-left-arrow {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(0, -50%);
}

.char-right-arrow {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
}

.char-scroll-arrow {
    width: 42px;
    height: 75px;
}

.char-scroll-container {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(0, -50%);
    min-height: 545px;
}

.characters {
    transition: left 0.2s;
}

.one-char {
    position: absolute;
    transform: translate(-50%, 0);
    height: 522px;
}

.scaled-char {
    width: 188px;
    height: 450px;
    margin: 36px auto 36px;
}

.scaled-char-3 {
    width: 304px;
    height: 456px;
    margin: 40px auto 120px;
}

.char-pippet {
    width: 15px;
    height: 15px;
    margin: 0 5px;
}

.select-char-button {
    width: 307px;
    height: 102px;
    margin: 5px 0 20px;
}

.name-entry-panel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 541px;
    height: 591px;
    background-color: #fff;
    border: solid 0px 0;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
}

.name-entry-button {
    width: 307px;
    height: 102px;
    margin: auto;
}

.name-input {
    width: 400px;
    height: 40px;
    margin: auto;
    background: #fff;
    font-family: OpenSansReg, Arial, Helvetica, sans-serif;
    font-size: 28pt;
    padding: 20px;
    border: 3px solid #d1d1d1;
    border-radius: 50em;
    text-align: center;
}

.name-input-holder {
    height: 140px;
}

.extended-form-input-holder {
    height: 100px;
}

.name-entry-button-holder {
    height: 156px;
}

.cv-hob-bg {
    background-image: url(./assets/babcock_menus/cv/hob_bg.png);
    background-repeat: repeat;
}

.cv-exp-bg {
    background-image: url(./assets/babcock_menus/cv/exp_bg.png);
    background-repeat: repeat;
}

.cv-left-arrow {
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translate(0, -50%);
    width: 50px;
    height: 180px;
    background: linear-gradient(90deg, rgba(43,149,209,1) 0%, rgba(43,149,208,0) 100%);
}

.cv-right-arrow {
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translate(0, -50%);
    width: 50px;
    height: 180px;
    background: linear-gradient(-90deg, rgba(43,149,209,1) 0%, rgba(43,149,208,0) 100%);
}

.cv-scroll-arrow {
    width: 21px;
    height: 37px;
    margin: 72px 15px;
}

.close-button {
    width: 72px;
    height: 77px;
}

.cv-section {
    margin: 0px 30px 5px;
    width: calc(100% - 60px);
}

.cv-progress-bar {
    position: absolute;
    width: 220px;
    height: 26px;
    border: 0px solid #000;
    border-radius: 10px;
    left: 118px;
    top: 88px;
}

.cv-progress-back {
    background-color: #0B346B;
}

.cv-progress-fill {
    background-color: #E71F85;
}

.cv-progress-border {
    outline: 4px solid #fff;
}

.cv-progress-perc {
    position: absolute;
    font-family: OpenSansBold;
    font-size: 12pt;
    color: #FFF;
    left: 228px;
    top: 100px;
    transform: translate(-50%, -50%);
    margin: 0;
}

.cv-badges-info {
    width: 424px;
    height: 17px;
}

.badge-info-panel-top {
    margin: -50px auto 0px;
}

.badge-info-panel {
    height: 400px !important;
}

.cv-badges-scroller {
    width: 100%;
    height: 180px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.cv-one-badge-holder {
    min-width: 160px;
    min-height: 160px;
    margin: 10px 10px;
    display: inline;
    position: relative;
}

.cv-badge-unlocked-icon {
    width: 160px;
    height: 160px;
}

.cv-badge-locked-icon {
    width: 132px;
    height: 132px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.cv-badge-back {
    position: absolute;
    width: 154px;
    height: 154px;
    background-color: #142659;
    border: solid 3px #142659;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.cv-badge-border {
    position: absolute;
    width: 154px;
    height: 154px;
    border: solid 3px #FFFFFF;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.badge-progress-bar {
    height: 160px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    margin-top: -10px;
}

.flex-columns {
    display: flex;
    flex-direction: row;
}

.flex-rows {
    display: flex;
    flex-direction: column;
}

.cv-profile-pic {
    margin: 20px 16px 0 0;
    width: 157px;
    height: 197px;
}

.cv-name {
    margin: 30px 0 16px 0;
    font-size: 20pt;
}

.cv-section-heading-container {
    width: 100%;
    border-bottom: solid 1px #fff;
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    color: #fff;
    margin-top: 3px;
    margin-bottom: 5px;
}

.cv-section-heading {
    font-family: OpenSansBold;
    text-transform: uppercase;
    font-size: 14pt;
    margin: 10px 0 0 5px;
}

.cv-complete-stat {
    margin: 15px 5px 0 0;
}

.cv-exp-section-icon {
    width: 36px;
    height: 36px;
    margin-top: 3px;
}

.cv-att-section-icon {
    width: 18px;
    height: 27px;
    margin-left: 4px;
    margin-right: 2px;
    margin-top: 9px;
}

.cv-hob-section-icon {
    width: 28px;
    height: 31px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 4px;
}

.cv-carrier {
    width: 366px;
    height: 132px;
}

.cv-section-star {
    width: 36px;
    height: 37px;
}

.cv-top-star {
    width: 44px;
    height: 44px;
}

.cv-exp-main-icon {
    width: 180px;
    height: 180px;
}

.cv-exp-icon-holder {
    width: 180px;
    position: relative;
    margin: auto;
}

.cv-exp-unlocked-star {
    position: absolute;
    right: 3px;
    top: 10px;
    width: 54px;
    height: 54px;
}

.cv-exp-locked-star-bg {
    position: absolute;
    right: 3px;
    top: 10px;
    width: 54px;
    height: 54px;
    background-color: #51A9DA;
    border: soild 0px #FFF;
    border-radius: 50%;
}

.cv-att-status-icon {
    width: 25px;
    height: 25px;
    margin: 5px;
}

.cv-att-text {
    padding: 6px 0 0 5px;
}

.cv-att-row {
    max-height: 32px;
}

.cv-selfie-status-icon {
    width: 120px;
    height: 125px;
}

.cv-selfie-icon-holder {
    margin-left: -5px;
    min-width: 110px;
    flex-grow: 1;
}

.cv-section-content-holder {
    margin: auto;
    width: 100%;
}

.cv-selfies-holder {
    margin: 0 auto;
    max-width: 640px;
    width: 100%;
}

.bottom-panels-bg {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -187px);
    background-color: #fff;
    width: 560px;
    height: 167px;
    border: 0px solid #fff;
    border-radius: 10px;
}

.prompt-holder {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -240px);
    width: 560px;
    height: 167px;
    border: 0px solid #fff;
    border-radius: 10px;
}

.prompt-panel {
    width: 560px;
    height: 130px;
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: rgba(0, 128, 201, 0.9);
    margin: 10px 0px;
    font-family: OpenSansLight;
    color: #fff;
}

.prompt-img {
    width: 115px;
    height: 118px;
}

.phone-ringing-icon {
    width: 121px;
    height: 169px;
    margin: -20px -10px 0px 20px;
    animation: shake 1.2s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: infinite;
}

.advisor-dialog-icon {
    width: 136px;
    height: 162px;
    margin: 6px 0 0 0;
    animation: scalein .7s cubic-bezier(.36,.07,.19,.97) both;
}

.bounce{
    animation: bounce 1.2s cubic-bezier(.36,.07,.19,.97) both;
    animation-iteration-count: infinite;
}

.cv-dialog-icon {
    width: 110px;
    height: 134px;
    margin: 20px;
}

.advisor-dialog-text {
    margin: 20px 20px 20px 5px;
    font-size: 14pt;
}

.prompt-text {
    text-align: left;
    margin: 8px 10px;
    font-size: 14pt;
}

.cv-prompt-text {
    margin: 25px auto 15px;
    font-size: 16pt;
}

.cv-prompt-button {
    width: 157px;
    height: 76px;
    margin: auto;
}

.cv-section-highlight {
    box-shadow: 0px 0px 0px 2px white inset;
}

.cv-section-darken {
    filter: contrast(0.6) grayscale(40%) blur(2px);
    background-color: rgba(0, 0, 0, 0.3);
}

.cv-exp-subtitle {
    font-family: OpenSansBold;
    text-transform: uppercase;
    margin: 0px auto;
    text-align: center;
}

.knock-back {
    opacity: 0.5;
}

.dialog-arrow-holder {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
}

.dialog-arrow {
    width: 32px;
    height: 19px;
}

.dialog-gradient {
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(102,102,102,1) 100%);
}

.behind-bottom-gradient {
    position: absolute;
    bottom: 0;
    height: 220px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
}

.settings-panel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 541px;
    height: 860px;
    background-color: #fff;
    border: solid 0px 0;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
}

.settings-resume-button {
    width: 407px;
    height: 102px;
    margin: 30px auto 10px;
}

.settings-quit-button {
    width: 187px;
    height: 77px;
}

.settings-button-group {
    width: 407px;
    margin: 10px auto 20px;
}

.maingo-button-group {
    width: 407px;
    margin: 10px auto 10px;
}

.settings-divider {
    width: 400px;
    height: 3px;
    margin: auto;
}

.settings-cta {
    width: 407px;
    height: 246px;
    margin: auto;
}

.gameover-cta {
    width: 407px;
    height: 221px;
    margin: auto;
}

.gameover-visitsl {
    width: 357px;
    height: 55px;
    margin: auto;
    margin-top: -7px;
    margin-bottom: 5px;
}

.cta-header {
    background-image: url(./assets//babcock_menus/cta_info/header.jpg);
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    max-height: 400px;
    flex-grow: 1;
}

.cta-info-button {
    width: 540px;
    height: 126px;
    margin: 10px auto;
}

.cta-info-button-bottom {
    width: 540px;
    height: 126px;
    margin: 10px auto 20px;
}

.inventory-panel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    min-height: 400px;
    background-color: #fff;
    border: solid 0px #000;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
}

.inventory-logo {
    width: 221px;
    height: 227px;
    margin: -120px auto 0px;
}

.inventory-items-container {
    margin: auto;
}

.one-inventory-item {
    width: 146px;
    height: 146px;
    background-color: #ECEEF0;
    border: solid 0px #000;
    border-radius: 50rem;
    position: relative;
    display: inline-block;
    margin: 10px;
}

.inventory-item {
    width: 140px;
    height: 140px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.task-item-container {
    width: 85%;
    margin: auto;
}

.task-list-check {
    width: 26px;
    height: 26px;
    margin: 5px 10px 5px 0px;
}

.task-list-text {
    margin: 3px auto 10px;
    font-size: 16pt;
    color: #6C757D;
}

.hud-timer-panel {
    margin: 0;
    width: 169px;
    height: 80px;
}

.hud-timer-bg {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 169px;
    height: 80px;
}

.hud-timer-readout {
    font-family: OpenSansBold;
    font-size: 24px;
    color: #6C757D;
    position: absolute;
    left: 86px;
}

.gen-white-panel {
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 541px;
    height: 86%;
    background-color: #fff;
    border: solid 0px #000;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
}

.mid-panel {
    top: 50%;
}

.pause-panel {
    height: 450px;
}

.gamestart-logo {
    width: 240px;
    height: 228px;
    margin: -100px auto 0px;
}

.gamestart-button {
    width: 406px;
    height: 102px;
    margin: auto;
}

.pre-game-star {
    width: 152px;
    height: 156px;
    margin: 0;
}

.pre-game-unlocks {
    width: 137px;
    height: 137px;
    margin: 0 10px;
}

.prev-best-text {
    margin: 0px auto 0px;
    font-size: 16pt;
    color: #6C757D;
    font-family: OpenSansBold;
}

.prev-best-text-end {
    margin: 0px auto 0px;
    font-size: 16pt;
    color: #FFF;
    font-family: OpenSansBold;
}

.rewards-unlocked-text {
    margin: 0px auto 0px;
    font-size: 14pt;
    color: #6C757D;
    text-transform: uppercase;
}

.rewards-unlocked-text {
    margin: 0px auto 0px;
    font-size: 14pt;
    color: #FFF;
    text-transform: uppercase;
}

.game-end-top-holder {
    width: 100%;
    min-height: 230px;
    flex-grow: 1;
}

.game-end-top-icon {
    width: 730px;
    height: 540px;
    position: absolute;
    left: 50%;
    top: 62%;
    transform: translate(-50%, -50%);
}

.game-end-play-again-button {
    width: 408px;
    height: 104px;
    margin: 10px auto;
}

.game-end-map-button {
    width: 258px;
    height: 78px;
    margin: 10px auto;
}

.reward-bg-burst {
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 628px;
    height: 614px;
}

.reward-icon {
    position: absolute;
    left: calc(50% - 149px);
    top: calc(50% - 148px);
    /*transform: translate(-50%, -50%);*/
    width: 298px;
    height: 296px;
    animation: scalein .7s cubic-bezier(.36,.07,.19,.97) both;
}

.reward-mid-holder {
    height: 400px;
}

.selfie-logo {
    width: 300px;
    height: 370px;
    margin: -100px auto 0px;
}

.take-selfie-button {
    width: 406px;
    height: 103px;
    margin: auto;
}

.selfie-result-pic {
    width: 326px;
    height: 352px;
    margin: 20px auto 0;
}

.selfie-result-progress-bar {
    width: 222px;
    height: 36px;
    margin: auto;
}

.selfie-result-progress-bar-segment {
    width: 42px;
    height: 28px;
}

.selfie-result-progress-stat {
    font-size: 18pt;
    color: #0C499C;
    font-family: OpenSansBold;
    margin: 0 auto;
}

.small-text {
    font-size: 10pt;
    font-family: OpenSansLight;
}

.transition-vehicle-img {
    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
    left: 50%;
    top: 58%;
    animation: vehicle-shake 0.16s linear 0s infinite;
}

@keyframes vehicle-shake {
    from {top: calc(58% - 0.5px)}
    to {top: calc(58% + 0.75px)}
}

.transition-progress-holder {
    position: absolute;
    top: calc(50% + 260px);
    left: calc(50% - 175px);
}

.transition-progress-inner {
    position: relative;
    width: 350px;
    height: 8px;
    margin: 0;
    padding: 0;
}

.transition-progress-bg {
    width: 350px;
    height: 8px;
}

.transition-icon {
    position: absolute;
    width: 42px;
    height: 57px;
}

.transition-start-icon {
    left: -18px;
    bottom: 5px;
}

.transition-end-icon {
    right: -18px;
    bottom: 5px;
}

.transition-progress-fill {
    position: absolute;
    left: calc(50% - 175px);
    top: 9px;
    width: 0px;
    height: 8px;
    background-color: #ffd000;
    border: 0px solid #000;
    border-radius: 50em;
    transition: width 2s;
    transition-timing-function: linear;
}

.tp-full {
    width: 350px;
}

.questions-top {
    height: 220px;
    margin: 0 auto;
}

.questions-advisor {
    width: 222px;
    height: 270px;
    margin: -60px auto 0;
}

.questions-continue-button {
    width: 407px;
    height: 102px;
    margin: auto;
}

.questions-buttons-holder {
    width: 408px;
    margin: auto;
}

.questions-mc-narrow {
    width: 190px;
    height: 84px;
    margin: auto;
    border-width: 2px;
    border-style: solid;
    border-radius: 50em;

    font-size: 13pt;
    color: #6C757D;
    font-family: OpenSansBold;

    cursor: pointer;

    position: relative;
}

.questions-mc-wide {
    width: 404px;
    height: 84px;
    margin: auto;
    border-width: 2px;
    border-style: solid;
    border-radius: 50em;

    font-size: 13pt;
    color: #6C757D;
    font-family: OpenSansBold;

    cursor: pointer;

    position: relative;
}

.mc-answer-text {
    width: 90%;

    text-align: center;
    margin: auto;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.question-answer-default {
    border-color: #6C757D;

    transition: all 0.25s;
}

.question-answer-selected {
    border-color: #E71F85;

    transition: all 0.25s;
}

.question-answer-unselected {
    border-color: #6C757D;
    opacity: 0.4;

    transition: all 0.25s;
}

.question-slider-opt {
    width: 40px;
    height: 40px;
    border-width: 2px;
    border-style: solid;
    border-radius: 50em;
    border-color: #fff;

    position: absolute;
    transform: translate(-50%, -50%);

    cursor: pointer;
}

.question-slider-opt-bg {
    width: 40px;
    height: 40px;
    border-width: 2px;
    border-style: solid;
    border-radius: 50em;
    border-color: #fff;

    position: absolute;
    transform: translate(-50%, -50%);

    background-color: #fff;
}

.question-slider-opt-text {
    margin-top: 5px;
    height: 40px;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 12pt;
    font-family: OpenSansBold;
    text-align: center;
}

.q-opt-1 {
    left: 0%;
    top: 50%;
}

.q-opt-2 {
    left: 33.3%;
    top: 50%;
}

.q-opt-3 {
    left: 66.6%;
    top: 50%;
}

.q-opt-4 {
    left: 100%;
    top: 50%;
}

.question-slider-default {
    background-color: #6C757D;
    color: #6C757D;

    transition: all 0.25s;
}

.question-slider-selected {
    background-color: #E71F85;
    color: #E71F85;

    transition: all 0.25s;
}

.question-slider-unselected {
    background-color: #6C757D;
    color: #6C757D;
    opacity: 0.4;

    transition: all 0.25s;
}

.slider-text {
    background-color: #fff;
}

.question-slider-holder {
    width: 350px;
    height: 40px;
    margin: auto;
    position: relative;
}

.question-slider-bg {
    background-image: url(./assets/babcock_menus/questions/Scale_bar.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.main-game-over-head {
    width: 100%;
    height: 200px;
}

.main-game-over-avatar {
    width: 216px;
    height: 252px;
    margin: -100px auto 0;
}

.mgo-congrats-banner {
    width: 428px;
    height: 78px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
}

.main-game-over-strip-bg {
    background-color: #148ACD;
    width: 100%;
    padding: 5px 0;
}

.result-screen-logo {
    width: 220px;
    height: 240px;
    margin: -50px auto 0px;
}

.result-progress-holder {
    height: 60px;
    margin: auto;
}

.result-progress-back {
    position: absolute;
    left: calc(50% - 220px);
    width: 440px;
    height: 36px;
    border: 0px solid #000;
    border-radius: 10px;
    background-color: #0B346B;
    margin: 6px auto;
}

.result-progress-fill {
    position: absolute;
    left: calc(50% - 220px);
    width: 200px;
    height: 36px;
    border: 0px solid #000;
    border-radius: 10px;
    margin: 6px auto;
    background-image: url(./assets/babcock_menus/cv_result/progress_fill.png);
    background-size: 460px 55px;
    background-repeat: no-repeat;
    background-position: center center;
}

.result-progress-border {
    position: absolute;
    left: 50%;
    top: -4px;
    transform: translate(-50%);
    width: 440px;
    height: 36px;
    border: 4px solid #FFF;
    border-radius: 10px;
    margin: 6px auto;
}

.result-progress-text {
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-size: 12pt;
    font-family: OpenSansBold;
    white-space: nowrap;
}

.result-stars-holder {
    width: 100%;
}

.result-stat-row {
    height: 32px;
}

.result-star {
    width: 70px;
    height: 70px;
    margin: 0 5px;
}

.result-star-icon-holder {
    position: relative;
    width: 70px;
    height: 70px;
    margin: 0 5px;
}

.result-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -52%);
}

.result-game-icon {
    width: 70px;
    height: 70px;
}

.result-att-icon {
    width: 34px;
    height: 52px;
}

.result-selfie-icon {
    width: 48px;
    height: 54px;
}

.result-game-icon-small {
    width: 34px;
    height: 34px;
}

.result-att-icon-small {
    width: 17px;
    height: 26px;
    margin: 5px 5px;
}

.result-selfie-icon-small {
    width: 24px;
    height: 26px;
    margin: 4px 5px;
}

.result-section-label {
    font-size: 14px;
    font-family: OpenSansBold;
    color: #0C499C;
    margin: 6px 12px;
    text-transform: uppercase;
}

.result-section-stat {
    font-size: 14px;
    font-family: OpenSansReg;
    color: #6C757D;
    margin: 6px 12px;
}

.section-complete {
    color: #3FAE29;
}

.result-sections-holder {
    width: 440px;
    margin: auto;
}

.section-marker {
    width: 18px;
    height: 18px;
    margin: 7px 0;
}


.react-preload-holder {
    position: fixed;
    left: -1000px;
    top: -1000px;
    width: 0px;
    height: 0px;
    overflow: hidden;
}

.contact-form-holder {
    width: 560px;
    margin: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.form-field-header {
    color: #348DD1;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    font-size: 16pt;
    font-family: OpenSansBold;
}

.wide-form-input {
    width: 560px;
    height: 50px;
    margin: 8px auto 15px;
    background: #fff;
    font-family: OpenSansReg, Arial, Helvetica, sans-serif;
    font-size: 24pt;
    padding: 10px;
    border: 3px solid #d1d1d1;
    border-radius: 50em;
    text-align: center;
}

.form-check {
    width: 58px;
    height: 58px;
    margin: 0px 30px auto 0px;
}

.form-info-text {
    margin: 0px auto 15px;
    font-size: 14pt;
    color: #6C757D;
}

.submit-form-button {
    width: 248px;
    height: 82px;
    margin: auto;
}

.form-error-text {
    font-family: OpenSansBold;
    margin: -14px 5px -7px;
    color: #e80000;
}

.error {
    border-color: #e80000;
}

.tutorial-c1 {
    display: inline-block;
    width: 190px;
}

.tutorial-c2 {
    display: inline-block;
    width: 60px;
    padding-top: 15px;
}

.tutorial-c3 {
    display: inline-block;
    width: 240px;
    padding-top: 12px;
}

.tut-white-panel {
    position: absolute;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 541px;
    height: 80%;
    background-color: #fff;
    border: solid 0px #000;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
}

.new-item-pop {
    width: 271px;
    height: 328px;
}

.share-button {
    width: 346px;
    height: 102px;
    margin: 10px auto;
}

.skip-tutorial-message {
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 0px 8px 8px 0px;
    background-color: #E71E84;
    border: solid 2px #fff;
    border-radius: 50em;
}

.skip-tutorial-message p {
    margin: 0px 8px 2px;
    font-size: 13pt;
    font-family: OpenSansBold;
    white-space: nowrap;
    color: #FFF;
    text-transform: uppercase;
}

@keyframes shake {
    5%, 45% {
      transform: translate3d(-1px, 0, 0);
    }
    
    10%, 40% {
      transform: translate3d(2px, 0, 0);
    }
  
    15%, 25%, 35% {
      transform: translate3d(-4px, 0, 0);
    }
  
    20%, 30% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes bounce {
    10%, 90% {
      transform: translate3d(0, -1px, 0);
      transform: scale(1,1);
    }
    
    20%, 80% {
      transform: translate3d(0, 2px, );
      transform: scale(1.1,1.1);
    }
  
    30%, 50% {
      transform: translate3d(0, 4px, 0);
      transform: scale(1.1,1.1);
    }
  
    40%, 60% {
      transform: translate3d(0, 4px, 0);
      transform: scale(1,1);
    }
  }
  @keyframes scalein {
    0%{
     
        transform: scale(0,0);
      }
    30%{
     
      transform: scale(1.1,1.1);
    }
    
    100% {
      transform: scale(1,1);
    }
  }

  .intro-video {
    margin: auto;
    width: 90%;
  }

  .skip-vid-button {
    width: 195px;
    height: 102px;
    margin: auto;
  }

body {
  margin: 0;
  padding: 0;
  position: fixed;
  background-color: #0B86CC;
  color: #fff;
}

canvas {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
}

.view-holder {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin: 0;
  opacity: 0;
  transition: opacity 0.35s;
}

.click-blocker {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.over-canvas {
  z-index: 10001;
}

.under-canvas {
  z-index: 1;
}

.view-row {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.shrink {
  flex-shrink: 1;
}

.grow {
  flex-grow: 1;
}

.half-column {
  width: 50%;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.centered {
  text-align: center;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.35s;
}

.no-select {
  -ms-user-select:none;
  -moz-user-select:none;
  -webkit-user-select:none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  user-select:none;
}

.default-cursor {
  cursor: default;
}

.example-panel {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 10px;
}

/* button classes */
.example-button {
  background-color: burlywood;
  border: 0px solid #000;
  border-radius: 50rem;
  width: 300px;
  height: 80px;
  padding: 1px 15px;
  margin: 5px auto;
}

.example-button p {
  font-size: 18pt;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
}

.active-button {
  cursor: pointer;
}

.white-bg {
  background-color: #fff;
}

/* all the below is from create-react-app and can probably go */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


